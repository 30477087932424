import { useEffect, useRef, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import firebase from './firebase/firebase';
import { UserContext } from './firebase/firebase';
import ErrorBoundary from './components/ErrorBoundary';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import AboutUs2 from './components/AboutUs2';
import ContactUs from './components/ContactUs';
import EBook from './components/EBook';
import FAQ from './components/FAQ';
import ForgotPassword from './components/ForgotPassword';
import Glossary from './components/Glossary';
import Home from './components/Home';
import Hotlist from './components/Hotlist';
import Login from './components/Login';
import Merchandise from './components/Merchandise';
import NotFound from './components/NotFound';
import RecordedQuestionAndAnswerSessions from './components/RecordedQ&As';
import RefundPolicy from './components/RefundPolicy';
import Register from './components/Register';
import Resources from './components/Resources';
import SignUp from './components/SignUp';
import TermsAndConditions from './components/TermsAndConditions';
import VideoSeries from './components/VideoSeries';

import './css/theme.css';
import './css/App.css';

const App = () => {
  const [auth, setAuth] = useState({ initialized: false, user: firebase.auth().currentUser });
  const history = useHistory();
  const historyRef = useRef(history);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_TRACKINGID!);
    ReactGA.set({ page: historyRef.current.location.pathname });
    ReactGA.pageview(historyRef.current.location.pathname);
  
    ReactPixel.init(process.env.REACT_APP_PIXELID!);
    ReactPixel.pageView();

    historyRef.current.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      ReactPixel.pageView();
      window.scrollTo(0, 0);
    });

    firebase.auth().onAuthStateChanged(firebaseUser => {
      ReactGA.set({
        user: firebaseUser,
      });
      ReactPixel.trackCustom("user", firebaseUser);
      setAuth({ initialized: true, user: firebaseUser });
    });
  }, [auth.user]);

  if(!auth.initialized) {
    return <></>;
  }
  
  return (
    <UserContext.Provider value={auth.user}>
      <ErrorBoundary>
        <Navigation />
        <Switch>
          <Route exact path='/' component={Home} />

          <Route path='/e-book'>
            {auth.user != null ? <Redirect to="/resources" /> : <EBook />}
          </Route>
          <Route path='/frequently-asked-questions' component={FAQ}/>
          <Route path='/merchandise' component={Merchandise} />
          <Route path='/register' component={Register} />

          <Route path='/login'>
            {auth.user != null ? <Redirect to="/resources" /> : <Login />}
          </Route>
          <Route path='/sign-up'>
            {auth.user != null ? <Redirect to="/resources" /> : <SignUp history={history} />}
          </Route>

          <Route path='/forgot-password' component={ForgotPassword}/>
          <Route path='/about-us' component={AboutUs2}/>
          <Route path='/contact-us' component={ContactUs}/>
          <Route path='/refund-policy' component={RefundPolicy}/>
          <Route path='/terms-and-conditions' component={TermsAndConditions}/>

          <Route path='/resources'>
            {auth.user == null ? <Redirect to="/login" /> : <Resources />}
          </Route>
          <Route path='/hotlist'>
            {auth.user == null ? <Redirect to="/login" /> : <Hotlist />}
          </Route>
          <Route path='/video-series'>
            {auth.user == null ? <Redirect to="/login" /> : <VideoSeries />}
          </Route>
          <Route path='/recorded-question-and-answer-sessions'>
            {auth.user == null ? <Redirect to="/login" /> : <RecordedQuestionAndAnswerSessions />}
          </Route>
          <Route path='/merchandise'>
            {auth.user == null ? <Redirect to="/login" /> : <Merchandise />}
          </Route>
          <Route path='/glossary'>
            {auth.user == null ? <Redirect to="/login" /> : <Glossary />}
          </Route>

          <Route path='*' component={NotFound}/>
        </Switch>
        <Footer />
      </ErrorBoundary>
    </UserContext.Provider>
  );
};

export default App;