import React from 'react';
import Alert from 'react-bootstrap/Alert'
import firebase, { emailsCollection } from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';

class Register extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    firstNameValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    lastNameValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    emailAddressValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    showModal: false
  };

  modalOptions: IModalOptions = {
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };
  
  handleInputChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + "Validation"]: {
        dirty: true,
        valid: event.target.checkValidity(),
        validationMessage: event.target.validationMessage
      }
    });
  };

  submitDisabled = () => {
    return (
      !this.state.firstNameValidation.dirty || !this.state.firstNameValidation.valid ||
      !this.state.lastNameValidation.dirty || !this.state.lastNameValidation.valid ||
      !this.state.emailAddressValidation.dirty || !this.state.emailAddressValidation.valid
    );
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    var email = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.emailAddress,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    emailsCollection.add(email).then(() => {
      this.modalOptions.title = "Registered with Learn2Stock!";
      this.modalOptions.body = "Thank you for registering with Learn2Stock. Be on the lookout for the latest from Learn2Stock!";
      this.modalOptions.action = "OK";
      this.modalOptions.link = "/";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    }).catch(() => {
      this.modalOptions.title = "ERROR";
      this.modalOptions.body = "You were not registered.";
      this.modalOptions.action = "Close";
      this.modalOptions.link = "";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  render() {
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9">
              <div className="card mb-0 card-body py-3">
                <h4>Register with Learn2Stock</h4>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input name="firstName" type="text" className="form-control" placeholder="First Name"
                          onChange={this.handleInputChange} required maxLength={100} />
                        <Alert show={this.state.firstNameValidation.dirty && !this.state.firstNameValidation.valid} variant="danger">
                          {this.state.firstNameValidation.validationMessage}
                        </Alert>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input name="lastName" type="text" className="form-control" placeholder="Last Name"
                          onChange={this.handleInputChange} required maxLength={100} />
                        <Alert show={this.state.lastNameValidation.dirty && !this.state.lastNameValidation.valid} variant="danger">
                          {this.state.lastNameValidation.validationMessage}
                        </Alert>
                      </div>
                    </div>
                  </div>
                     <div className="form-group">
                    <input type="email" name="emailAddress" className="form-control" placeholder="Email Address" 
                                        onChange={this.handleInputChange} required maxLength={100} pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" />                                    
                    <Alert show={this.state.emailAddressValidation.dirty && !this.state.emailAddressValidation.valid} variant="danger">
                      {this.state.emailAddressValidation.validationMessage}
                    </Alert>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-learn2stock" disabled={this.submitDisabled()}>
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>    
        </div>
      </section>
    );
  };
}

export default Register;