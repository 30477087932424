import React from 'react';
import Flickity from 'react-flickity-component';
import ryan from '../img/ryan.jpg';
import paris from '../img/paris.jpg';
import james from '../img/james.jpg';
import corey from '../img/corey.jpg';
import ashley from '../img/ashley.jpg';
import ozzy from '../img/ozzy.jpeg';


class AboutUs extends React.Component {
  flickityOptions = {
    autoPlay: 5000,
    initialIndex: 1,
    cellAlign: "center",
    contain: true,
    imagesLoaded: true,
    wrapAround: true
  };

  eBookPreviews = [

    (
      <div key="paris" className="col-6 col-md-4 d-flex">
        <div className="card">
          <img src={paris} width={300} height={200} alt="Paris B. Garrett" />
          <div className="card-body py-3">
            <h5 className="mb-0">Paris B. Garrett</h5>
            <span><i>Founder</i> <br/>Operations/Software Engineer</span>
          </div>
          <div className="card-footer pt-0 border-top-0">
            <a href="https://www.linkedin.com/in/parisgarrett/" className="link-learn2stock" target="_blank" rel="noreferrer">LinkedIn</a>
          </div>
        </div>
      </div>
    ),

    (
        <div key="corey" className="col-6 col-md-4 d-flex">
          <div className="card">
            <img src={corey} width={300} height={200} alt="Corey Hatcher" />
            <div className="card-body py-3">
              <h5 className="mb-0">Corey Hatcher</h5>
              <span>HR Consultant</span>
            </div>
            <div className="card-footer pt-0 border-top-0">
              <a href="https://www.linkedin.com/in/corey-hatcher-71334421/" className="link-learn2stock" target="_blank" rel="noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>
      ),

      (
        <div key="ryan" className="col-6 col-md-4 d-flex">
          <div className="card">
            <img src={ryan} width={300} height={200} alt="Ryan Diaz" />
            <div className="card-body py-3">
              <h5 className="mb-0">Ryan Diaz</h5>
              <span><i>Co-Founder</i> <br />Content Liaison</span>
            </div>
            <div className="card-footer pt-0 border-top-0">
              <a href="https://www.linkedin.com/in/ryan-diaz-577a6067/" className="link-learn2stock" target="_blank" rel="noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>
      ),

    (
        <div key="james" className="col-6 col-md-4 d-flex">
          <div className="card">
            <img src={james} width={300} height={200} alt="James Garcia" />
            <div className="card-body py-3">
              <h5 className="mb-0">James Garcia</h5>
              <span><i>Co-Founder</i> <br />Marketing/Graphics Design</span>
            </div>
            <div className="card-footer pt-0 border-top-0">
                <a href="https://www.linkedin.com/in/james-garcia-92a33720b/" className="link-learn2stock" target="_blank" rel="noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>
      ),

      (
        <div key="ozzy" className="col-6 col-md-4 d-flex">
          <div className="card">
            <img src={ozzy} width={300} height={200} alt="Osman Ahmed" />
            <div className="card-body py-3">
              <h5 className="mb-0">Osman Ahmed</h5>
              <span>Senior Web Developer</span>
            </div>
            <div className="card-footer pt-0 border-top-0">
                <a href="https://www.linkedin.com/in/osman-ahmed-b726781a2/" className="link-learn2stock" target="_blank" rel="noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>
      ),
    

    (
      <div key="ashley" className="col-6 col-md-4 d-flex">
        <div className="card">
          <img src={ashley} width={300} height={200} alt="Ashley Irving" />
          <div className="card-body py-3">
            <h5 className="mb-0">Ashley Irving</h5>
            <span>Business Operations</span>
          </div>
          <div className="card-footer pt-0 border-top-0">
            <a href="https://www.linkedin.com/in/ashleyirving/" className="link-learn2stock" target="_blank" rel="noreferrer">LinkedIn</a>
          </div>
        </div>
      </div>
    )
  ];

  render() {
    return (
      <section>
        <h4 className="text-center mb-0">Who We Are</h4><br></br>
        <div className="text-center mb-3">
          <div><b>Learn2Stock</b> is comprised of a few real people that have survived our own respective bumps and bruises along this journey towards financial freedom.</div>
          <br></br>
          <div>Now, we have come together to develop a platform where we can help <b>YOU</b> navigate the world of stock trading much more easily.</div>
          <div>We thank you for choosing to grow with us, and we look forward to our journey ahead, together, as we continue to spread financial literacy throughout the culture!</div>
        </div>
        <div className="overflow-x-hidden">
          <div className="container">
            <Flickity className="slider-highlight-selected" options={this.flickityOptions}>
              {this.eBookPreviews}
            </Flickity>
          </div>
        </div>
      </section>
    );
  };
}

export default AboutUs;