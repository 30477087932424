import React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-5 col-md-7">
              <div className="card py-3 shadow-lg">
                <div className="card-body">
                  <h1 className="display-3 mb-3 text-center">404</h1>
                  <p className="lead">The page you were looking for wasn't found.</p>
                  <Link to="/" className="link-learn2stock lead">Learn2Stock</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default NotFound;