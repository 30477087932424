import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import IModalOptionsProps from '../interfaces/IModalOptions';

const ModalWindow = (props: IModalOptionsProps) => {
  const history = useHistory();

  const handleClose = () => {
    props.modalOptions.closeModal();
  }

  const handleClick = () => {
    if(props.modalOptions.link) {
      history.push(props.modalOptions.link);
    }
    else {
      handleClose();
    }
  }
  
  return (
    <Modal show={props.modalOptions.show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{props.modalOptions.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.modalOptions.body}</p>
      </Modal.Body>    
      <Modal.Footer>
        <Button variant="learn2stock" onClick={handleClick}>{props.modalOptions.action}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWindow;