import React from 'react';
import { videosCollection } from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import IVideos, { IVideo } from '../interfaces/IVideos';
import ModalWindow from './ModalWindow';

class VideoSeries extends React.Component {
  state = {
    searchTerm: "",
    videoCategory: "Latest Releases",
    videoTiles: [],
    showModal: false
  };
  videos: IVideo[] = [];
  videoCategories: JSX.Element[] = [];
  videoTiles: JSX.Element[] = [];
  modalOptions: IModalOptions = {
    title: "ERROR",
    body: "The video series was not retrieved.",
    action: "Close",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };

  updateSearchTerm = (event: any) => {
    this.setState({ searchTerm: event.target.value });
  };

  updateVideoCategory = (event: any) => {
    this.setState({ videoCategory: event.target.value });
  };

  getVideos = () => {
    videosCollection.doc("videoSeries").get().then((doc) => {
      var data = doc.data() as IVideos;
      this.videos = data.videos;
      this.videoCategories = this.videos.map((video) => video.category)
        .filter((category, index, categories) => categories.indexOf(category) === index)
        .map((videoCategory) => {
          return <option key={videoCategory} value={videoCategory}>{videoCategory}</option>
        });
      this.videoTiles = this.videos.map((video, index) => {
        return this.getVideoTile(video, index);
      });
      this.setState({ videoTiles: this.videoTiles });
    }).catch(() => {
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  getVideoTile(video: IVideo, index: number, playVideo = false) {
    var videoPlayIconClass = "video-cover rounded";
    var videoSrc = video.videoSrc;
    if(playVideo) {
      videoPlayIconClass = "video-cover video-cover-playing rounded";
      videoSrc += "?rel=0&showinfo=0&autoplay=1";
    }
    var videoTile = (
      <div key={video.title} data-category={video.category} className="col-lg-4 mb-3">
        <div className={videoPlayIconClass}>
          <img alt={video.title} src={video.imageSrc} className="bg-image" />
          <div className="video-play-icon" onClick={ () => this.playVideo(video, index) }></div>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" title={video.title} src={videoSrc} allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
        </div>
        <div className="text-dark text-center">{video.title}</div>
      </div>
    );
    return videoTile;
  };

  playVideo = (video: IVideo, index: number) => {
    var videoTile = this.getVideoTile(video, index, true);
    this.videoTiles[index] = videoTile;
    this.setState({ videoTiles: this.videoTiles });
  };

  componentDidMount = () => {
    this.getVideos();
  };

  render() {
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="text-center mb-3">
            <h4 className="mb-0">
              If you take a stock tip from someone, you'll make profit for a day.<br />
              If you Learn2Stock yourself, you'll be rich forever!
            </h4>
            <div className="font-weight-bold">#richTogether #bigGains #Learn2Stock</div>
          </div>
          <div className="form-group d-flex flex-wrap flex-sm-nowrap">
            {
              this.videoCategories.length > 0 &&
              <>
                <input type="text" placeholder="Search Videos" className="form-control flex-fill mb-2" onChange={this.updateSearchTerm} />
                <select value={this.state.videoCategory} className="form-control w-auto flex-fill" onChange={this.updateVideoCategory}>
                  {this.videoCategories}
                </select>
              </>
            }
          </div>
          <div className="row justify-content-around">
            {this.videoTiles.filter((videoTile) => videoTile.props["data-category"] === this.state.videoCategory && videoTile.key?.toString().toLowerCase().includes(this.state.searchTerm.toLowerCase()))}
          </div>
        </div>
      </section>
    );
  };
}

export default VideoSeries;