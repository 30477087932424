import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import 'firebase/auth';
import firebase from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';

class Login extends React.Component {
  state = {
    emailAddress: "",
    password: "",
    keepMeLoggedIn: true,
    showModal: false,
    emailAddressValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    passwordValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    }
  };

  modalOptions: IModalOptions = {
    title: "ERROR",
    body: "Unable to log in. Verify the email address and password are correct.",
    action: "Close",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };
  
  handleInputChange = (event: any) => {
    const validatedFields = [ "emailAddress", "password" ];
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value
    });
    if(validatedFields.includes(event.target.name)) {
      this.setState({
        [event.target.name + "Validation"]: {
          dirty: true,
          valid: event.target.checkValidity(),
          validationMessage: event.target.validationMessage
        }
      });
    }
  };

  loginButtonDisabled = () => {
    return (
      !this.state.emailAddressValidation.dirty || !this.state.emailAddressValidation.valid || 
      !this.state.passwordValidation.dirty || !this.state.passwordValidation.valid
    );
  }

  login = (event: any) => {
    event.preventDefault();
    const persistence = this.state.keepMeLoggedIn ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE;
    firebase.auth().setPersistence(persistence).then(() => {
      firebase.auth().signInWithEmailAndPassword(this.state.emailAddress, this.state.password).catch(() => {
        if(persistence === firebase.auth.Auth.Persistence.SESSION) {
          localStorage.setItem("loggedIn", "yes");
        }
        this.modalOptions.show = true;
        this.setState({ showModal: true });
      });
    }).catch(() => {
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };;

  render() {
    return (
      <section>        
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-7">
              <div className="card py-3 shadow-lg">
                <div className="card-body">
                  <h4 className="text-center">Welcome back</h4>
                  <form onSubmit={this.login}>
                    <div className="form-group">
                      <input name="emailAddress" type="email" className="form-control" placeholder="Email Address" 
                        onChange={this.handleInputChange} required maxLength={100} pattern="^\w+(\.?\w+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$" />
                      <Alert show={this.state.emailAddressValidation.dirty && !this.state.emailAddressValidation.valid} variant="danger">
                        {this.state.emailAddressValidation.validationMessage}
                      </Alert>
                    </div>
                    <div className="form-group">
                      <input name="password" type="password" className="form-control" placeholder="Password" 
                        onChange={this.handleInputChange} required minLength={6} maxLength={100} />
                      <Alert show={this.state.passwordValidation.dirty && !this.state.passwordValidation.valid} variant="danger">
                        {this.state.passwordValidation.validationMessage}
                      </Alert>
                      <div className="d-flex justify-content-end">
                        <small>
                          <Link to="/forgot-password" className="link-learn2stock">Forgot password?</Link>
                        </small>
                      </div>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-block btn-lg btn-learn2stock" type="submit" disabled={this.loginButtonDisabled()}>Log in</button>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input id="keepMeLoggedIn" name="keepMeLoggedIn" type="checkbox" className="custom-control-input"
                          onChange={this.handleInputChange} checked={this.state.keepMeLoggedIn} />
                        <label className="custom-control-label" htmlFor="keepMeLoggedIn">Keep me logged in</label>
                      </div>
                    </div>
                  </form>
                  <div className="text-center text-small mt-3">
                    <span>Don't have an account? <Link to="/sign-up" className="link-learn2stock">Sign up here</Link></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default Login;