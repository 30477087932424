import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookF, faInstagram, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBell, faChartLine, faClipboardList, faComments, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Flickity from 'react-flickity-component';
import { UserContext } from '../firebase/firebase';
import homepage1 from '../img/freedom-special.png';
import homepage2 from '../img/l2s_old_home.png';
import wally from '../img/wally.png';
import james from '../img/jg.jpg';
import lui from '../img/lui.jpg';
import meesha from '../img/avatar-female-2.jpg';
import aqua from '../img/aqua_logo.png';
import clearLogo from '../img/L2S-Logo-blue.png';


class Home extends React.Component {
  static contextType = UserContext;
  state= {
    playVideo: false
  };
  videoPlayIconClass = "video-cover rounded";
  videoSrc = "https://www.youtube.com/embed/dOTEJkqXjYM";

  playVideo = () => {
    this.videoPlayIconClass = "video-cover video-cover-playing rounded";
    this.videoSrc += "?rel=0&showinfo=0&autoplay=1";
    this.setState({ playVideo: true });
  };

  reviews = [
    {
      reviewer: "Steph",
      comment: "My favorite part is that you don't have to know anything! They have experts in the group teaching you everything that you need to learn...",
      image: lui
    },
    {
      reviewer: "James",
        comment: "...The Learn2Stock guys are very knowledgeable and passionate about teaching others about stocks and ways to grow their wealth in a safe and responsible way!",        image: james
    },
    {
      reviewer: "Wally",
      comment: "You can start with as little as you can, but you have to be ready to learn. For sure!",
      image: wally
    },
    {
      reviewer: "Meesha",
      comment: "I never thought trading stocks could be so simple.",
      image: meesha
    }
  ];

  feedback = this.reviews.map((review) => 
    <div key={review.reviewer} className="col-md-6 col-xl-5 text-center">
      <div className="h4">{'"' + review.comment + '"'}</div>
      <img src={review.image} alt={review.reviewer} className="avatar" />
      <div className="mt-2">{review.reviewer}</div>
    </div>
  );

  flickityOptions = {
    autoPlay: 5000,
    initialIndex: this.reviews.length / 2,
    cellAlign: "center",
    contain: true,
    imagesLoaded: true,
    wrapAround: true
  };

  render() {
    //let eBookLink = "/e-book";
    let signUpLink = "/sign-up";

    let videoSeriesLink, hotlistLink, liveAlertsLink, questionAndAnswerSessionsLink, portfolioAnalysisLink, groupChatLink;
    videoSeriesLink = hotlistLink = liveAlertsLink = questionAndAnswerSessionsLink = portfolioAnalysisLink = groupChatLink = "/sign-up";
    if (this.context) {
      signUpLink = "/resources";
      videoSeriesLink = "/video-series";
      hotlistLink = "/hotlist";
      liveAlertsLink = "/resources";
      questionAndAnswerSessionsLink = "/recorded-question-and-answer-sessions";
      portfolioAnalysisLink = "/resources";
      groupChatLink = "/resources";
    }
    return (
      <>
        <section className="bg-repeated-squares p-0">
          <div className="row no-gutters">
            <div className="col-sm-6 d-flex justify-content-center px-3 py-5">
              <div className="col-md-10 col-lg-9 col-xl-8 d-flex flex-column justify-content-center"> 
                {/*<h1 className="align-self-center text-center mb-2"><b>Learn2Stock</b><img className="custom-logo" src={aqua} alt="Learn2Stock Aqua Logo" /><br /><br /><br /><b>Trade</b> Our <b>Alerts.</b><br /><br /><br />Make <b>More</b> Money.</h1><br/>*/}
                <h1 className="align-self-center text-center mb-2"><b>Trade</b> Our <b>Alerts.</b><br /><br /><br />Make <b>More</b> Money.</h1><br/>

                <Link to="/sign-up" className="btn btn-lg btn-learn2stock align-self-center">Get Started FOR FREE!</Link>                                                     
              </div>
            </div>
            <div className="col-sm-6 d-flex flex-column">
              <Link to={signUpLink} className="flex-fill">
              <img src={homepage1} alt="Learn2Stock" className="w-50 h-10" />
              <img src={clearLogo} alt="Learn2Stock" className="w-50 h-10" />
              </Link>
              <div className="bg-white lead font-weight-bold text-center pb-3">Our step-by-step E-book will get you caught up in minutes!</div>
            </div>
          </div>
        </section>
        <section className="bg-learn2stock-gradient py-4">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-6 col-lg col-md-2 mb-4 mb-md-0 d-flex justify-content-center">
                <a href="https://www.facebook.com/groups/Learn2Stock/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon className="text-white opacity-50" icon={faFacebook} size="3x" />
                </a>
              </div>
              <div className="col-6 col-lg col-md-2 mb-4 mb-md-0 d-flex justify-content-center">
                <a href="https://www.facebook.com/Learn2Stock/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon className="text-white opacity-50" icon={faFacebookF} size="3x" />
                </a>
              </div>
              <div className="col-6 col-lg col-md-2 mb-4 mb-md-0 d-flex justify-content-center">
                <a href="https://twitter.com/learn2stock" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon className="text-white opacity-50" icon={faTwitter} size="3x" />
                </a>
              </div>
              <div className="col-6 col-lg col-md-2 mb-4 mb-md-0 d-flex justify-content-center">
                <a href="https://www.instagram.com/learn2stock_official/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon className="text-white opacity-50" icon={faInstagram} size="3x" />
                </a>
              </div>
              <div className="col-6 col-lg col-md-2 mb-4 mb-md-0 d-flex justify-content-center">
                <a href="https://www.tiktok.com/@learn2stock?lang=en" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon className="text-white opacity-50" icon={faTiktok} size="3x" />
                </a>
              </div>
              <div className="col-6 col-lg col-md-2 mb-4 mb-md-0 d-flex justify-content-center">
                <a href="https://www.youtube.com/channel/UCp7W8OsZG4pKyn_9w3RFWAQ" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon className="text-white opacity-50" icon={faYoutube} size="3x" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-mint-stripes">
          <div className="container">
            <h4 className="text-center mb-4">Learn2Stock is an Exclusive Financial Literacy Platform.</h4>
            <div className="row mb-md-4">
              <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
                <Link to={videoSeriesLink} className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                  <FontAwesomeIcon className="text-danger" icon={faYoutube} size="3x" />
                  <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Video Series</div>
                  <p>Come watch us break down the charts and learn how to improve your gains!</p>
                </Link>
              </div>
              <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
                <Link to={hotlistLink} className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                  <FontAwesomeIcon className="text-peru" icon={faClipboardList} size="3x" />
                  <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Daily Hotlists</div>
                  <p>Too busy to stay glued to your phone? Our "Hotlist" has daily swing trade ideas to make life easier!</p>
                </Link>
              </div>
              <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
                <Link to={liveAlertsLink} className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                  <FontAwesomeIcon className="text-warning" icon={faBell} size="3x" />
                  <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Live Market Alerts</div>
                  <p>Live, high-volume stock alerts sent straight to your phone, all throughout the day!</p>                  
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
                <Link to={questionAndAnswerSessionsLink} className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                  <FontAwesomeIcon className="text-info" icon={faQuestionCircle} size="3x" />
                  <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Live Q&amp;A Sessions</div>
                  <p>Join our monthly trading seminars as we deep dive on various technical analysis topics.</p>
                </Link>
              </div>
              <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
                <Link to={portfolioAnalysisLink} className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                  <FontAwesomeIcon className="text-success" icon={faChartLine} size="3x" />
                  <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Portfolio Analysis</div>
                  <p>Feel free to have our experts pull up the charts on your favorite stocks, anytime!</p>
                </Link>
              </div>
              <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
                <Link to={groupChatLink} className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                  <FontAwesomeIcon className="text-primary" icon={faComments} size="3x" />
                  <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Private Group Chat</div>
                  <p>Need some extra assistance? Join our private group chat for one-on-one coaching, any day of the week!</p>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-0">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-lg-5 align-self-center text-center mb-3 mb-lg-0">
                <div className="h2">Trading Stocks Can Be Complicated...</div>
                <div className="lead">We make it simple!</div>
              </div>
              <div className="col-lg-6">
                <div className={this.videoPlayIconClass}>
                  <img alt="Learn2Stock Intro" src={homepage2} className="bg-image" />
                  <div className="video-play-icon bg-white" onClick={ () => this.playVideo() }></div>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" title="Learn2Stock Intro" src={this.videoSrc} allow="autoplay; encrypted-media" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="overflow-x-hidden">
            <div className="container">
              <Flickity className="slider-highlight-selected" options={this.flickityOptions}>
                {this.feedback}
              </Flickity>
            </div>
          </div>
        </section>
      </>
    );
  };
}

export default Home;