import { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import firebase, { UserContext } from '../firebase/firebase';
import banner from '../img/l2s-logo-banner.png';

const Navigation = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  const logout = () => {
    firebase.auth().signOut().then(() => {
      history.push('/');
    });
  };
  
  return (
    <Navbar bg="light" expand={ user == null ? "sm" : "xl" }>
      <NavLink to='/' className="navbar-brand p-0">
        <img src={banner} alt="Learn2Stock Banner" height="40" />
      </NavLink>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {
          user == null &&
          <>
            <Nav className="mr-auto">
              <NavLink to='/e-book' className="nav-link">E-Book</NavLink>
              <NavLink to='/about-us' className="nav-link">About Us</NavLink>
              <NavLink to='/frequently-asked-questions' className="nav-link">FAQ</NavLink>
              <NavLink to='/merchandise' className="nav-link">Merchandise</NavLink>
            </Nav>
            <Nav>
              <NavLink to='/login' className="nav-link">Log In</NavLink>
              <NavLink to='/sign-up' className="nav-link">Sign Up</NavLink>
            </Nav>
          </>
        }
        {
          user != null &&
          <>
            <Nav className="mr-auto">
              <NavLink to='/about-us' className="nav-link">About Us</NavLink>
              <NavLink to='/resources' className="nav-link">Resources</NavLink>
              <NavLink to='/hotlist' className="nav-link">Hotlist</NavLink>
              <NavLink to='/video-series' className="nav-link">Video Series</NavLink>
              <NavLink to='/recorded-question-and-answer-sessions' className="nav-link">Recorded Q&amp;As</NavLink>
              <NavLink to='/merchandise' className="nav-link">Merch</NavLink>
              <NavLink to='/glossary' className="nav-link">Glossary</NavLink>
              <NavLink to='/frequently-asked-questions' className="nav-link">FAQ</NavLink>
            </Nav>
            <button type="button" className="btn-nav-link" onClick={logout}>Log Out</button>
          </>
        }
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
