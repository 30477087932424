import React from 'react';
import { Link } from 'react-router-dom';
import Flickity from 'react-flickity-component';
import index from '../img/index.jpg';
import sample from '../img/ebook_sample2.jpg';
import cover from '../img/cover.jpg';

class EBook extends React.Component {
  flickityOptions = {
    autoPlay: 5000,
    initialIndex: 0,
    cellAlign: "center",
    contain: true,
    imagesLoaded: true,
    wrapAround: true
  };

  eBookPreviews = [
    (
      <div key="cover" className="col-md-6 col-xl-5 text-center">
        <Link to="/sign-up">
          <img src={cover} alt="E-Book Cover" className="w-100 max-height-75 d-inline" />
        </Link>
      </div>
    ),
    (
      <div key="sample" className="col-md-6 col-xl-5 text-center">
        <Link key="sample" to="/sign-up">
          <img src={sample} alt="E-Book Sample" className="w-100 max-height-75 d-inline" />
        </Link>
      </div>
    ),
    (
      <div key="index" className="col-md-6 col-xl-5 text-center">
        <Link key="index" to="/sign-up">
          <img src={index} alt="E-Book Index" className="w-100 max-height-75 d-inline" />
        </Link>
      </div>
    )
  ];

  render() {
    return (
      <section>
        <h4 className="text-center mb-0">From Zero to the Moon!</h4>
        <div className="text-center mb-3">
          The #1 beginners guide guaranteed to teach you how to skyrocket your portfolio!
          Available FREE to all Learn2Stock.com subscribers.
        </div>
        <div className="overflow-x-hidden">
          <div className="container">
            <Flickity className="slider-highlight-selected" options={this.flickityOptions}>
              {this.eBookPreviews}
            </Flickity>
          </div>
        </div>
      </section>
    );
  };
}

export default EBook;