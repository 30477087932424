import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import firebase from '../firebase/firebase';
import  { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';

class ForgotPassword extends React.Component {
  state = {
    emailAddress: "",
    showModal: false,
    emailAddressValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    }
  };

  modalOptions: IModalOptions = {
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };
  
  updateEmailAddress = (event: any) => {
    this.setState({
      emailAddress: event.target.value,
      emailAddressValidation: {
        dirty: true,
        valid: event.target.checkValidity(),
        validationMessage: event.target.validationMessage
      }
    });
  };

  sendResetPasswordLinkButtonDisabled = () => {
    return !this.state.emailAddressValidation.dirty || !this.state.emailAddressValidation.valid;
  }

  sendResetPasswordLink = (event: any) => {
    event.preventDefault();
    firebase.auth().sendPasswordResetEmail(this.state.emailAddress).then(() => {
      this.modalOptions.title = "RESET PASSWORD LINK SENT!";
      this.modalOptions.body = "A link to reset your password has been sent to your email address.";
      this.modalOptions.action = "OK";
      this.modalOptions.link = "/";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    }).catch(() => {
      this.modalOptions.title = "ERROR";
      this.modalOptions.body = "The reset password link was not sent. Please verify the email address you entered is correct.";
      this.modalOptions.action = "Close";
      this.modalOptions.link = "";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  render() {
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-7">
              <div className="card py-3 shadow-lg">
                <div className="card-body">
                  <h2 className="text-center">Reset Password</h2>
                  <form onSubmit={this.sendResetPasswordLink}>
                    <div className="form-group">
                      <input id="email" type="email" className="form-control" placeholder="Email Address" 
                        onChange={this.updateEmailAddress} required maxLength={100} pattern="^\w+(\.?\w+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$" />
                      <Alert show={this.state.emailAddressValidation.dirty && !this.state.emailAddressValidation.valid} variant="danger">
                        {this.state.emailAddressValidation.validationMessage}
                      </Alert>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-block btn-lg btn-learn2stock" type="submit" disabled={this.sendResetPasswordLinkButtonDisabled()}>
                        Send reset password link
                      </button>
                    </div>
                  </form>
                  <div className="text-center text-small mt-3">
                    <span>
                      Already know your password? <Link to="/login" className="link-learn2stock">Log in here</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default ForgotPassword;