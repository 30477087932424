import React from 'react';
import { videosCollection } from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import IVideos, { IVideo } from '../interfaces/IVideos';
import ModalWindow from './ModalWindow';
import ryanqa from '../img/ryanqa.png';
import parisqa from '../img/parisqa.png';
import jamesqa from '../img/jamesqa.png';

class RecordedQuestionAndAnswerSessions extends React.Component {
  state = {
    videoTiles: [],
    showModal: false
  };
  videos: IVideo[] = [];
  videoTiles: JSX.Element[] = [];
  modalOptions: IModalOptions = {
    title: "ERROR",
    body: "The Q&A sessions were not retrieved.",
    action: "Close",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };

  getVideos = () => {
    videosCollection.doc("recordedQuestionAndAnswerSessions").get().then((doc) => {
      var data = doc.data() as IVideos;
      this.videos = data.videos;
      this.videoTiles = this.videos.map((video, index) => {
        return this.getVideoTile(video, index);
      });
      this.setState({ videoTiles: this.videoTiles });
    }).catch(() => {
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  getVideoTile(video: IVideo, index: number, playVideo = false) {
    var videoPlayIconClass = "video-cover rounded";
    var videoSrc = video.videoSrc;
    if(playVideo) {
      videoPlayIconClass = "video-cover video-cover-playing rounded";
      videoSrc += "?rel=0&showinfo=0&autoplay=1";
    }
    var videoTile = (
      <div key={video.title} className="col-lg-4 mb-3">
        <p className="text-center">{video.updatedAt.toDate().toDateString()}</p>
        <div className={videoPlayIconClass}>
          <img alt={video.title} src={video.imageSrc} className="bg-image" />
          <div className="video-play-icon" onClick={ () => this.playVideo(video, index) }></div>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" title={video.title} src={videoSrc} allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
        </div>
        <div className="text-dark text-center">{video.title}</div>
      </div>
    );
    return videoTile;
  };

  playVideo = (video: IVideo, index: number) => {
    var videoTile = this.getVideoTile(video, index, true);
    this.videoTiles[index] = videoTile;
    this.setState({ videoTiles: this.videoTiles });
  };

  componentDidMount = () => {
    this.getVideos();
  };

  render() {
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="row justify-content-around mb-3">
            <h4 className="col-lg-12 text-center">Join Us At Our Monthly Simple Trading Seminars!</h4>
            <div className="col-lg-4 mb-3">
              <img src={ryanqa} alt="Learn to Stock screenshot of a live virtual Q&amp;A session with Ryan Diaz teaching stop/losses and take/profits" />
              <div className="text-dark text-center">Ryan</div>
            </div>
            <div className="col-lg-4 mb-3"><img src={parisqa} alt="Learn to Stock screenshot of a live virtual Q&amp;A session with Paris Garrett teaching about averaging down" />
              <div className="text-dark text-center">Paris</div>
            </div>
            <div className="col-lg-4 mb-3">
              <img src={jamesqa} alt="Learn to Stock screenshot of a live virtual Q&amp;A session with James Garcia teaching about paper trading" />
              <div className="text-dark text-center">James</div>
            </div>
          </div>
          <div className="row justify-content-around">
            {this.videoTiles}
          </div>
        </div>
      </section>
    );
  };
}

export default RecordedQuestionAndAnswerSessions;