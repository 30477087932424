import { createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/storage";
import 'firebase/auth';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_SB,
    messagingSenderId: process.env.REACT_APP_SID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MID
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const db = firebase.firestore();

if (process.env.REACT_APP_USEFIREBASELOCALEMULATOR === "YES") {
    db.useEmulator('localhost', 8080);
    firebase.auth().useEmulator('http://localhost:9099/');
}

export const emailsCollection = db.collection('emails');
export const glossaryCollection = db.collection('glossary');
export const hotlistsCollection = db.collection('hotlists');
export const membersCollection = db.collection('members');
export const messagesCollection = db.collection('messages');
export const videosCollection = db.collection('videos');

export const eBookRef = firebase.storage().ref().child('e-book/From Zero To The Moon.pdf');
export const hotlistsRef = firebase.storage().ref().child('hotlists');

export const UserContext = createContext(firebase.auth().currentUser);

export default firebase;
