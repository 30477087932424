import Alert from 'react-bootstrap/Alert';
import services from '../img/services_free.jpg';

const Subscription = (props: any) => {
  return (
      <>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input id="subscribedToEmail" name="subscribedToEmail" type="checkbox" className="custom-control-input" value={props.subscribedToEmail}
              onChange={props.handleInputChange} />
            <label className="custom-control-label" htmlFor="subscribedToEmail">Yes, I agree to stay updated and get the latest from Learn2Stock</label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input id="agreedToTermsAndConditions" name="agreedToTermsAndConditions" type="checkbox" className="custom-control-input" value={props.agreedToTermsAndConditions}
              onChange={props.handleInputChange} required />
            <label className="custom-control-label" htmlFor="agreedToTermsAndConditions">
              I have read and agree to the <a href="/terms-and-conditions" target="_blank" rel="noreferrer" className="link-learn2stock">Terms And Conditions</a>.
            </label>
            <Alert show={props.agreedToTermsAndConditionsValidation.dirty && !props.agreedToTermsAndConditionsValidation.valid} variant="danger">
              {props.agreedToTermsAndConditionsValidation.validationMessage}
            </Alert>
          </div>
        </div>
       
        {/*<img id="subscription-image" src={services} alt="Learn2Stock" className="mb-3" />*/}
        <div className="text-center">
        <button className="btn btn-block btn-lg btn-learn2stock" type="button" onClick={props.signUp} disabled={props.signUpDisabled()}>Sign Up</button>
        </div>
    </>
  );
};

export default Subscription;