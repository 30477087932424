import React from 'react';
import firebase, { membersCollection } from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';
import MemberInformation from './MemberInformation';
import Subscription from './Subscription';

class SignUp extends React.Component<any> {
  state = {
    firstName: "",
    lastName: "",
    referrer: "",
    phoneNumber: "",
    emailAddress: "",
    password: "",
    subscribedToEmail: false,
    agreedToTermsAndConditions: false,
    showModal: false,
    firstNameValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    lastNameValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    referrerValidation: {
      dirty: false,
      valid: true,
      validationMessage: ""
    },
    phoneNumberValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    emailAddressValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    passwordValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    agreedToTermsAndConditionsValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    }
  };

  modalOptions: IModalOptions = {
    title: "ERROR",
    action: "OK",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };
  
  handleInputChange = (event: any) => {
    const validatedFields = [ "firstName", "lastName", "referrer", "phoneNumber", "emailAddress", "password", "agreedToTermsAndConditions" ];
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value
    });
    if(validatedFields.includes(event.target.name)) {
      this.setState({
        [event.target.name + "Validation"]: {
          dirty: true,
          valid: event.target.checkValidity(),
          validationMessage: event.target.validationMessage
        }
      });
    }
  };

  

  signUpDisabled = () => {
    return (
      !this.state.firstNameValidation.dirty || !this.state.firstNameValidation.valid ||
      !this.state.lastNameValidation.dirty || !this.state.lastNameValidation.valid ||
      !this.state.phoneNumberValidation.dirty || !this.state.phoneNumberValidation.valid ||
      !this.state.referrerValidation.valid ||
      !this.state.emailAddressValidation.dirty || !this.state.emailAddressValidation.valid ||
      !this.state.passwordValidation.dirty || !this.state.passwordValidation.valid ||
      !this.state.agreedToTermsAndConditionsValidation.dirty || !this.state.agreedToTermsAndConditionsValidation.valid
    );
  };

  signUp = () => {
    firebase.auth().createUserWithEmailAndPassword(this.state.emailAddress, this.state.password).then(() => {
      let member = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailAddress: this.state.emailAddress,
        phoneNumber: Number(this.state.phoneNumber),
        referrer: this.state.referrer,
        subscribedToEmail: this.state.subscribedToEmail,
        agreedToTermsAndConditions: this.state.agreedToTermsAndConditions,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      membersCollection.add(member).then(() => {
        this.props.history.push('/resources');
      }).catch(() => {
        this.modalOptions.body = "You have successfully created an account but there was an issue processing your application. Please let us know what happened so we can help.";
        this.modalOptions.link = "/contact-us";
        this.modalOptions.show = true;
        this.setState({ showModal: true });
      });
    }).catch(() => {
      this.modalOptions.body = "There was an issue creating your account. Please let us know what happened so we can help.";
      this.modalOptions.link = "/contact-us";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  render() {
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="row justify-content-center mx-0">
          <div className="col-lg-7 col-md-9 col-sm-12 px-0">
            <div className="card card-body">
              <h4 className="text-center">Create Account</h4>
              <form>
                <MemberInformation 
                  handleInputChange={this.handleInputChange}
                  firstName={this.state.firstName}
                  firstNameValidation={this.state.firstNameValidation}
                  lastName={this.state.lastName}
                  lastNameValidation={this.state.lastNameValidation}
                  referrer={this.state.referrer}
                  referrerValidation={this.state.referrerValidation}
                  phoneNumber={this.state.phoneNumber}
                  phoneNumberValidation={this.state.phoneNumberValidation}                
                  emailAddress={this.state.emailAddress}
                  emailAddressValidation={this.state.emailAddressValidation}
                  password={this.state.password}
                  passwordValidation={this.state.passwordValidation}
                />
                <Subscription 
                  handleInputChange={this.handleInputChange}
                  subscribedToEmail={this.state.subscribedToEmail}
                  agreedToTermsAndConditions={this.state.agreedToTermsAndConditions}
                  agreedToTermsAndConditionsValidation={this.state.agreedToTermsAndConditionsValidation}
                  signUpDisabled={this.signUpDisabled}
                  signUp={this.signUp}
                />
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SignUp;