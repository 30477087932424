
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookF, faInstagram, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBell, faComments, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { eBookRef, UserContext } from '../firebase/firebase';
import cover from '../img/cover.jpg';

class Resources extends React.Component {
  static contextType = UserContext;
  state = {
    eBookLink: ""
  };

  componentDidMount = () => {
    eBookRef.getDownloadURL().then((eBookUrl) => {
      this.setState({ eBookLink: eBookUrl });
    }).catch(() => {});
  }

  render() {
    const isFirstTimeUser = this.context.metadata.creationTime === this.context.metadata.lastSignInTime;
    return (
      <section>
        <div className="container">
          {
            isFirstTimeUser &&
            <h1 className="text-center mb-3">Thank you for subscribing and welcome to Learn2Stock.com!</h1>
          }
          
          <h4 className="text-center mb-1">Join our exclusive alert channel &amp; chat rooms on Telegram</h4>
          <div className="font-weight-bold text-center mb-3">
            (You will not be able to join the alert channel or chat rooms unless you have the Telegram App installed.
            Visit <a href="https://telegram.org/" target="_blank" rel="noreferrer" className="link-learn2stock">Telegram.org</a> to download the app.)
          </div>
          <div className="row mb-3">
            <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
              <a href="https://t.me/joinchat/UWiNwnDnxSBQFjCS" target="_blank" rel="noreferrer" 
                className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                <FontAwesomeIcon className="text-warning" icon={faBell} size="3x" />
                <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Alert Channel</div>
                <p>The "Alert Channel" is where we stream LIVE up-to-the-minute, stock alerts, stock tips, stock updates, and even stock news all throughout the day!</p>
              </a>
            </div>
            <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
              <a href="https://t.me/joinchat/VO0pDRC9XzKpaxZu" target="_blank" rel="noreferrer" 
                className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                <FontAwesomeIcon className="text-primary" icon={faComments} size="3x" />
                <div className="h6 link-learn2stock hover-underline mt-2 mb-1">General Chat Room</div>
                <p>Use this private chatroom to ask any and all stock/crypto related questions that you may have OR get 1-on-1 coaching &amp; tips!</p>
              </a>
            </div>
            <div className="col-12 col-md-4 d-md-flex mb-4 mb-md-0">
              <a href="https://t.me/joinchat/5bGiLA7vPIk2MzZh" target="_blank" rel="noreferrer" 
                className="d-block width-100 bg-white rounded text-center hover-shadow p-3">
                <FontAwesomeIcon className="text-info" icon={faInfoCircle} size="3x" />
                <div className="h6 link-learn2stock hover-underline mt-2 mb-1">Options/Trade Ideas Chat Room</div>
                <p>This is the chat room where we discuss various OPTION plays, as well as key picks from our daily Hotlist.</p>
              </a>
            </div>
          </div>
          
          {
            this.state.eBookLink !== "" &&
            <div className="row mb-3">
              <div className="col-sm-6 mb-1">
                <a href={this.state.eBookLink} target="_blank" rel="noreferrer">
                  <img src={cover} alt="Learn2Stock E-Book" className="w-100 max-height-50" />
                </a>
              </div>
              <div className="col-sm-6 d-flex align-items-center">
                <div>
                  <a className="link-learn2stock" href={this.state.eBookLink} target="_blank" rel="noreferrer">
                    From Zero to the Moon
                  </a> was specifically designed to guide you step-by-step as you start
                  your day trading journey, without over complicating things. Once you finish, you will
                  understand exactly what a broker is, how to PROPERLY use our Alert Channel &amp;
                  "Hotlist", how to define your trading plan, how to read charts, when to buy/sell, and
                  much more!
                </div>
              </div>
            </div>
          }

          <h4 className="text-center mb-1">Follow us on social media!</h4>
          <div className="row mb-3">
            <div className="col-sm-4 text-center p-2">
              <a href="https://www.facebook.com/groups/Learn2Stock/" className="link-learn2stock" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="text-facebook" /> Join our Facebook group.
              </a>
            </div>
            <div className="col-sm-4 text-center p-2">
              <a href="https://www.facebook.com/Learn2Stock/" className="link-learn2stock" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="text-facebook" /> Follow us on Facebook.
              </a>
            </div>
            <div className="col-sm-4 text-center p-2">
              <a href="https://twitter.com/learn2stock" className="link-learn2stock" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="text-twitter" /> Follow us on Twitter.
              </a>
            </div>
            <div className="col-sm-4 text-center p-2">
              <a href="https://www.instagram.com/learn2stock_official/" className="link-learn2stock" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="text-instagram" /> Follow us on Instagram.
              </a>
            </div>
            <div className="col-sm-4 text-center p-2">
              <a href="https://www.tiktok.com/@learn2stock?lang=en" className="link-learn2stock" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTiktok} className="text-tiktok" /> Follow us on TikTok.
              </a>
            </div>
            <div className="col-sm-4 text-center p-2">
              <a href="https://www.youtube.com/channel/UCp7W8OsZG4pKyn_9w3RFWAQ" className="link-learn2stock" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube} className="text-red" /> Subscribe to our YouTube channel.
              </a>
            </div>
          </div>

          {
            isFirstTimeUser &&
            <>
              <p>
                Thank you again for your interest in Learn2Stock.
                Please enjoy meeting the Learn2Stock community and share this group with everyone you know who would like to earn more money!
              </p>
              <p>
                We are proud to have you join our Learn2Stock investing group, and we look forward to our journey ahead together as we continue to spread financial literacy throughout the culture!
              </p>    
              <p>Respectfully,<br />Learn2Stock.com</p>
            </>
          }

        </div>
      </section>
    );
  };
}

export default Resources;