import React from 'react';
import { Link } from 'react-router-dom';

class RefundPolicy extends React.Component {
  render() {
    return (
      <section>
        <div className="container">
          <h4 className="text-center mb-3">Return, Refund &amp; Cancellation Policy</h4>
          <div className="mb-3">
            <div className="font-weight-bold text-decoration-underline">Definitions and key terms</div>
            <p>To help explain things as clearly as possible in this Return, Refund &amp; Cancellation Policy, every time any of these terms are referenced, it is referring to Learn2Stock:</p>
            <p className="ml-3">&bull;<span className="ml-1">Company: when this policy mentions Company, we, us, or our, it refers to Learn2Stock, that is responsible for your information under this Return, Refund &amp; Cancellation Policy.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">Customer: refers to the company, organization or person that signs up to use the Learn2Stock Service to manage the relationships with your consumers or service users.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Learn2Stock and use the services.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">Service: refers to the service provided by Learn2Stock as described in the relative terms and on this platform.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">Website: Learn2Stock's site, which can be accessed via this URL: Learn2stock.com</span></p>
          </div>
          <div className="mb-3">
            <div className="font-weight-bold text-decoration-underline">Return, Refund &amp; Cancellation Policy</div>
            <p>
                Thanks for subscribing to Learn2Stock.
                We appreciate you for joining us in this journey to become even more financially literate.
                We also want to make sure you have a rewarding experience while you are exploring, evaluating, and utilizing our product(s).
            </p>
            <p>
                As with any shopping experience, there are terms and conditions that apply to transactions at Learn2Stock.
                We'll be as brief as our attorneys will allow.
                The main thing to remember is that by placing an order, subscribing, or making a purchase at Learn2Stock, you agree to the terms set forth below along with Learn2Stock's Privacy Policy.
            </p>
            <p>
                If there is something wrong with the product/service you bought, or if you are not happy with it, we will not be able to issue a refund.
            </p>
          </div>
          <div className="mb-3">
            <div className="font-weight-bold text-decoration-underline">Subscription Cancellation</div>
            <p>
                Because we work hard to maintain exclusivity of our services, we are unable to issue any refunds for our subscriptions, but you will be able to cancel anytime to avoid any future charges!
                All payments/subscriptions are made through PayPal.
            </p>
          </div>
          <div className="mb-3">
            <div className="font-weight-bold text-decoration-underline">Refunds</div>
            <p>
                We at Learn2Stock commit ourselves to serving our customers with the best products!
                Every single product that you choose is thoroughly inspected, checked for defects, and packaged with utmost care.
                We do this to ensure that you fall in love with our products.
            </p>
            <p>
                Sadly, there are times when we may not have the product(s) that you choose in stock, or may face some issues with our inventory and quality check.
                In such cases, we may have to cancel your order.
                If this should ever happen, you will be informed about it in advance.
                If you have purchased via Online payment (not Cash on Delivery), then you will be refunded once our team confirms your request.
            </p>
            <p>
                We carry out thorough quality checks before processing the ordered item and we take utmost care while packing the product.
                We also ensure that the packing is done in such a way that your items will not get damaged during transit.
                Please note that Learn2Stock is not liable for damages that are caused to the items during transit or transportation.
            </p>
            <p>We follow certain policies to ensure transparency, efficiency, and quality customer care:</p>
            <p className="ml-3">&bull;<span className="ml-1">We <span className="text-decoration-underline">DO NOT</span> allow returns on sold products/services - online or in retail outlets.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">We <span className="text-decoration-underline">DO NOT</span> accept returned goods, as we believe that customers should get the best quality products.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">Refunds are <span className="text-decoration-underline">NOT</span> given for any purchases made - be they online or in retail store.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">We <span className="text-decoration-underline">DO NOT</span> allow exchanges of our products.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">We <span className="text-decoration-underline">DO NOT</span> engage in reselling used products and discourage the same, because we cannot ensure the best quality products for our customers.</span></p>
            <p>For International Orders:</p>
            <p className="ml-3">&bull;<span className="ml-1">We <span className="text-decoration-underline">DO NOT</span> support Exchanges or Returns.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">If you cancel the order before we process it and dispatch for shipping, a refund can be processed. Orders generally take 1-2 days to process before dispatch.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">Orders already in shipping cannot be returned, canceled or refunded.</span></p>
            <p className="ml-3">&bull;<span className="ml-1">If you face any issues, please <Link to="/contact-us" className="link-learn2stock">contact our Support Team</Link> immediately.</span></p>
          </div>
          <div className="mb-3">
            <div className="font-weight-bold text-decoration-underline">Your Consent</div>
            <p>By using our website, registering an account, or making a purchase, you hereby consent to our Return, Refund &amp; Cancellation Policy and agree to its terms.</p>
          </div>
          <div className="mb-3">
            <div className="font-weight-bold text-decoration-underline">Changes to Our Return, Refund &amp; Cancellation Policy</div>
            <p>
                Should we update, amend, or make any changes to this document in order to more accurately reflect our Service and policies (unless otherwise required by law) those changes will be prominently posted here.
                Then, if you continue to use the Service, you will be bound by the updated Return, Refund &amp; Cancellation Policy.
                If you do not want to agree to this or any updated Return, Refund &amp; Cancellation Policy, you can cancel your subscription at any time.
            </p>
          </div>
          <div>
            <div className="font-weight-bold text-decoration-underline">Contact Us</div>
            <p>If, for any reason, you are not completely satisfied with any good or service that we provide, please do not hesitate to <Link to="/contact-us" className="link-learn2stock">contact us</Link>.</p>
          </div>
        </div>
      </section>
    );
  };
}

export default RefundPolicy;