import React from 'react';
import { Link } from 'react-router-dom';
import { faFacebook, faFacebookF, faInstagram, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FAQ extends React.Component {
  state = {
    searchFAQ: "",
    FAQsRetrieved: false,
    showModal: false
  };
  FAQ = [
    {
      question: "How should I go about starting?",
      answer: (
        <i>
          We <span className="text-decoration-underline">HIGHLY</span> suggest grabbing a copy of the Learn2Stock E-book. 
          This book is designed to literally walk you through all the beginner trading steps, one by one. 
          Once you finish, you’ll be more than ready to start utilizing the various tools that we provide inside our subscription channels.
        </i>
      )
    },
    {
      question: "Do you offer signals?",
      answer: (
        <i>
          Yes! Our “Alert Channel” has everything from penny stocks, OTC stocks, crypto currencies, and stock option alerts. 
          All scanned for high volume among a few other indicators. 
          Never trade blindly, ever again!
        </i>
      )
    },
    {
      question: "How can I join Learn2Stock’s private channels?",
      answer: (
        <i>
          Good Question! 
          Our private channels are available only to those who subscribe! 
          Subscription cost as a little as $1 a day, 
          and even less if you sign up for an annual subscription. <Link to="/sign-up" className="link-learn2stock">Fill out our application to join.</Link>
        </i>
      )
    },
    {
      question: "I am subscribed to Learn2Stock.com, there was no “Hotlist” released Friday night!?!",
      answer: (
        <i>
          Our hotlist are our daily suggested swing trades. 
          Not everyone is able to keep an eye on the alert channel all day, so this is a much more “hand-off” way to trade. 
          We release a new list every morning Monday – Friday. 
          On weekends the market is closed, so you will not see any alerts or a hotlist until that next business day. 
        </i>
      )
    },
    {
      question: "If I just give you some money, can Learn2Stock trade it for me?",
      answer: (
        <i>
          Nope, nope, and nope! 
          That is illegal! 
          Learn2Stock is a federally trademarked LLC seeking Better Business Bureau accreditation. 
          We <span className="text-decoration-underline">DO NOT</span> condone anything “underhanded”.
        </i>
      )
    },
    {
      question: "What is an “exit strategy”?",
      answer: (
        <i>
          Each trader should have their own risk/reward measured <span className="text-decoration-underline">BEFORE</span> entering a position. 
          You should already know how much you are willing to lose in order to go for a profit, and when you will sell in order to take profit. 
          Our motto is, “Don’t Be Greedy and Always Secure the Bag!”
        </i>
      )
    },
    {
      question: "When Does The Stock Market Open?",
      answer: (
        <i>
          The stock market opens every weekday from 9:30 am – 4pm EST. 
          The market is closed on weekends and federal holidays.
        </i>
      )
    },
    {
      question: "Is there any way I could get a FREE copy of your E-book?",
      answer: (
        <i>
          Of Course! 
          Everyone who subscribes to our monthly subscription gets a free 7 day trial <span className="text-decoration-underline">AND</span> a free copy of our E-book. 
          The Learn2Stock E-book is designed to literally walk you through all the beginner trading steps. 
          Once you finish, you’ll be more than ready to start utilizing the various tools that we provide inside our subscription channels.
        </i>
      )
    },
    {
      question: "How Much Money Do I Need To Start Investing?",
      answer: (
        <i>
          None! 
          You can actually start practicing by paper trading right away. 
          Paper trading is offered through most brokerage platforms (Webull and TD Ameritrade to name a couple) and it is like trading real stocks, but with Monopoly money. 
          There is no minimum deposit required for opening a paper trading account, and it is great practice!
        </i>
      )
    },
    {
      question: "How do I Cancel My Subscription?",
      answer: (
        <i>
          If, In fact, you do decide to make less money trading by canceling our alert services, that is completely ok! 
          Both of our subscriptions are designed so that you can cancel anytime. 
          You will even retain access until the end of your billing cycle! 
          The easiest way to cancel is to login to your PayPal account and cancel there. 
          If you need any help, <Link to="/contact-us" className="link-learn2stock">simply reach out to us</Link> and we will be glad to assist you!
        </i>
      )
    },
    {
      question: "What is the refund policy? ",
      answer: (
        <i>
          Generally speaking, we do not offer any refunds on any of our products/services.
        </i>
      )
    },
    {
      question: "Do you offer 1-on-1 training?",
      answer: (
        <i>
          Yes! 
          It is broken up and will come in the form of our E-book, our videos, our group chats, and our monthly trading seminars. 
          At any point, <Link to="/contact-us" className="link-learn2stock">please feel free to reach out to us</Link> if you have any additional questions.
        </i>
      )
    },
    {
      question: "Where can I follow your social media pages?",
      answer: (
        <i>
          <div>Learn2Stock is all over social media:</div>
          <ul className="list-style-type-none pl-0">
            <li>
              <a href="https://facebook.com/groups/learn2stock" target="_blank" rel="noreferrer" className="link-learn2stock">
                <FontAwesomeIcon className="icon-width-default text-facebook mr-2" icon={faFacebook} />Facebook Group
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Learn2Stock" target="_blank" rel="noreferrer" className="link-learn2stock">
                <FontAwesomeIcon className="icon-width-default text-facebook mr-2" icon={faFacebookF} />Facebook
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/learn2stock" target="_blank" rel="noreferrer" className="link-learn2stock">
                <FontAwesomeIcon className="icon-width-default text-twitter mr-2" icon={faTwitter} />Twitter
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/learn2stock_official" target="_blank" rel="noreferrer" className="link-learn2stock">
                <FontAwesomeIcon className="icon-width-default text-instagram mr-2" icon={faInstagram} />Instagram
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@learn2stock" target="_blank" rel="noreferrer" className="link-learn2stock">
                <FontAwesomeIcon className="icon-width-default text-tiktok mr-2" icon={faTiktok} />TikTok
              </a>
            </li>
            <li>
              <a href="https://www.learn2stock.com/youtube.html" target="_blank" rel="noreferrer" className="link-learn2stock">
                <FontAwesomeIcon className="icon-width-default text-red mr-2" icon={faYoutube} />YouTube
              </a>
            </li>
            <li>
              <a href="https://act.webull.com/actv2/niv/3fpVm2ox4IYy/k2c/main" target="_blank" rel="noreferrer" className="link-learn2stock">
                <FontAwesomeIcon className="icon-width-default text-dark mr-2" icon={faGift} />Webull Offer Code (Get a free stock!)
              </a>
            </li>
          </ul>
        </i>
      )
    },
    {
      question: "What is an option?",
      answer: (
        <i>
          An option is a contract that gives you the right to purchase/sell a specific stock at a specific price.
        </i>
      )
    },
    {
      question: "What is the difference between the “bid” and “ask”?",
      answer: (
        <i>
          The “Bid” is the price you pay for a stock/option and the “Ask” is the price you would sell at.
        </i>
      )
    },
    {
      question: "What is a candlestick?",
      answer: (
        <i>
          A candlestick represents a stock’s price high, low, open, and close for a specified period of time.
        </i>
      )
    },
    {
      question: "How do I get unlimited day trades?",
      answer: (
        <i>
          Every broker is different on this one. 
          On Robinhood, a user is only allowed three “day trades” in a five day period unless your account is over $25k. 
          Webull (one of our favorite brokers) allows for unlimited day trades, 
          but you can typically only trade with “settled funds” (more on this in the E-book).
        </i>
      )
    },
    {
      question: "I see your alert channel has both premarket and afterhours alerts, but my broker/app only let’s me trade when the market is open, what is going on?",
      answer: (
        <i>
          Again, every broker is different. 
          Some “Traditional” brokers (TD Ameritrade) don’t allow premarket or after hours trading at all. 
          Some brokers/apps charge extra to trade during these hours. 
          Also, the time that pre-market/afterhours trading starts and ends varies depending on the broker.
        </i>
      )
    },
    {
      question: "What if I don’t have PayPal?",
      answer: (
        <i>
          PayPal also has an option to use any major credit and debit card. 
          We DO NOT personally handle any of your payment information. 
          For security reasons, all finances go through PayPal. <Link to="/sign-up" className="link-learn2stock">Fill out our application</Link> and choose the payment option that is most convenient for you.
        </i>
      )
    },
    {
      question: "Do you cater to beginners?",
      answer: (
        <i>
          Yes! 
          You should picture Learn2Stock.com (and all of our services) as serving two main purposes. 
          <div>#1 we are here to show beginner level investors how to get started trading <b className="text-decoration-underline">WITHOUT</b> having to pay thousands for some “Guru Course”.</div>
          <div>#2 once our beginners are up to speed, our alert channel and hotlist keep you aware of all of the best trades, all throughout the day.</div>
          All you have to do is buy, sell, and make money! 
          Simple!
        </i>
      )
    },
    {
      question: "How frequent are the live webinars?",
      answer: (
        <i>
          We typically do at least one “Simple Trading Seminar” every month. 
          This gives us a chance to go in-depth on a particular topic every month. 
          We also spend the last half of the seminar performing Q&amp;A’s for all of our subscribers!
        </i>
      )
    },
    {
      question: "How much money can I potentially make?",
      answer: (
        <i>
          That really depends on your trading strategy. 
          Always keep in mind that <b className="text-decoration-underline">more money makes more money</b>. 
          Meaning try not to withdraw your gains if you can help it! 
          The larger your account grows = the more money you will make each trade!
        </i>
      )
    },
    {
      question: "Does Learn2Stock have a support system if I need to ask a question?",
      answer: (
        <i>
          Yes! 
          We actually have several private chat channels where our subscribers our encouraged to ask any of their trading questions 24/7. 
          There is no such thing as a “dumb question”. 
          Learn2Stock is a judgment free zone, so please feel free to ask away! 
          That is the only way you will learn.
        </i>
      )
    },
    {
      question: "Which chart indicators do you use?",
      answer: (
        <i>
          The Stochastic, RSI and volume indicators.
        </i>
      )
    },
    {
      question: "What is the cost of the subscription?",
      answer: (
        <i>
          Our subscription is $30 a month (cancel anytime). 
          Once you realize how amazing our services are, 
          you will probably want to subscribe to our annual membership that is $300 for an entire year (saves you a little over 16%).
        </i>
      )
    },
    {
      question: "What is a trend line?",
      answer: (
        <i>
          A trend line outlines 2 peaks (swing highs) and/or (swing lows).
        </i>
      )
    }
  ];

  updateSearchQuestion = (event: any) => {
    this.setState({ searchFAQ: event.target.value });
  };

  render() {
    const FAQ = this.FAQ.filter((faq) => faq.question.toLowerCase().includes(this.state.searchFAQ.toLowerCase())).map((faq) => {
      return (
        <div key={faq.question} className="mb-3">
          <div><b>{faq.question}</b></div>
          {faq.answer}
        </div>
      );
    });
    return (
      <section>
        <div className="container">
          <div className="mb-3">
            <input type="text" placeholder="Search Frequently Asked Questions" className="form-control" 
              onChange={this.updateSearchQuestion} />
          </div>
          { FAQ }
          <div className="lead">
            If you need any help,
            <Link to="/contact-us" className="link-learn2stock"> simply reach out to us </Link>
            and we will be glad to assist you!
          </div>
        </div>
      </section>
    );
  };
}

export default FAQ;