import Alert from 'react-bootstrap/Alert';

const MemberInformation = (props: any) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <input name="firstName" type="text" className="form-control" placeholder="First Name" value={props.firstName}
              onChange={props.handleInputChange} required maxLength={100} />
            <Alert show={props.firstNameValidation.dirty && !props.firstNameValidation.valid} variant="danger">
              {props.firstNameValidation.validationMessage}
            </Alert>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <input name="lastName" type="text" className="form-control" placeholder="Last Name" value={props.lastName}
              onChange={props.handleInputChange} required maxLength={100} />
            <Alert show={props.lastNameValidation.dirty && !props.lastNameValidation.valid} variant="danger">
              {props.lastNameValidation.validationMessage}
            </Alert>
          </div>
        </div>
      </div>
      <div className="form-group">
        <input name="referrer" type="text" className="form-control" placeholder="Referrer Name (Optional)" value={props.referrer}
          onChange={props.handleInputChange} maxLength={100} />
        <Alert show={!props.referrerValidation.valid} variant="danger">
          {props.referrerValidation.validationMessage}
        </Alert>
      </div>
      <div className="form-group">
        <input name="phoneNumber" type="tel" className="form-control" placeholder="Cell Number" value={props.phoneNumber}
          onChange={props.handleInputChange} required maxLength={15} pattern="^\d+$" />
        <Alert show={props.phoneNumberValidation.dirty && !props.phoneNumberValidation.valid} variant="danger">
          {props.phoneNumberValidation.validationMessage}
        </Alert>
      </div>
      <div className="form-group">
        <input name="emailAddress" type="email" className="form-control" placeholder="Email Address" value={props.emailAddress}
          onChange={props.handleInputChange} required maxLength={100} pattern="^\w+(\.?\w+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$" />
        <Alert show={props.emailAddressValidation.dirty && !props.emailAddressValidation.valid} variant="danger">
          {props.emailAddressValidation.validationMessage}
        </Alert>
      </div>
      <div className="form-group">
        <input name="password" type="password" className="form-control" placeholder="Create a Password" value={props.password}
          onChange={props.handleInputChange} required minLength={6} maxLength={100} />
        <Alert show={props.passwordValidation.dirty && !props.passwordValidation.valid} variant="danger">
          {props.passwordValidation.validationMessage}
        </Alert>
      </div>
    </>
  );
};

export default MemberInformation;