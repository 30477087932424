import React from 'react';
import allMerch from '../img/all_bands.jpg';
import shirt from '../img/shirt1.jpg';
import mug from '../img/mug.jpg';

class Merchandise extends React.Component {
  render() {
    return (
      <section>
        <h4 className="text-center">COMING SOON...</h4>
        <div className="container">
          <div className="row">
            <div className="col-md-4 d-md-flex mb-3 mb-md-0">
              <div className="d-block width-100 bg-white rounded text-center p-3">
                <img src={shirt} alt="Learn2Stock Mug" />
              </div>
            </div>

            <div className="col-md-4 d-md-flex mb-3 mb-md-0">
              <div className="d-block width-100 bg-white rounded text-center p-3">
                <img src={allMerch} alt="Learn2Stock Mug" className="mb-1" />
                <div className="font-weight-bold">We've got the drip.</div>
              </div>
            </div>

            <div className="col-md-4 d-md-flex mb-3 mb-md-0">
              <div className="d-block width-100 bg-white rounded text-center p-3">
                <img src={mug} alt="Learn2Stock Mug" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default Merchandise;