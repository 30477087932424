import React from 'react';
import Alert from 'react-bootstrap/Alert'
import firebase, { messagesCollection } from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';

class ContactUs extends React.Component {
  state = {
    name: "",
    emailAddress: "",
    message: "",
    showModal: false,
    nameValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    emailAddressValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    messageValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    }
  };

  modalOptions: IModalOptions = {
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };
  
  handleInputChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + "Validation"]: {
        dirty: true,
        valid: event.target.checkValidity(),
        validationMessage: event.target.validationMessage
      }
    });
  };

  sendMessageButtonDisabled = () => {
    return (
      !this.state.nameValidation.dirty || !this.state.nameValidation.valid || 
      !this.state.emailAddressValidation.dirty || !this.state.emailAddressValidation.valid || 
      !this.state.messageValidation.dirty || !this.state.messageValidation.valid
    );
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    var message = {
      name: this.state.name,
      emailAddress: this.state.emailAddress,
      message: this.state.message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    messagesCollection.add(message).then(() => {
      this.modalOptions.title = "MESSAGE RECEIVED";
      this.modalOptions.body = "Thanks for reaching out to us! We'll get back to you as soon as we can.";
      this.modalOptions.action = "OK";
      this.modalOptions.link = "/";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    }).catch(() => {
      this.modalOptions.title = "ERROR";
      this.modalOptions.body = "Your message was not received.";
      this.modalOptions.action = "Close";
      this.modalOptions.link = "";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  render() {
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9">
              <div className="card mb-0 card-body py-3">
                <h4>Have questions? Hit us up!</h4>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input type="text" name="name" className="form-control" placeholder="Name" 
                      onChange={this.handleInputChange} required maxLength={100} />
                    <Alert show={this.state.nameValidation.dirty && !this.state.nameValidation.valid} variant="danger">
                      {this.state.nameValidation.validationMessage}
                    </Alert>
                  </div>
                  <div className="form-group">
                    <input type="email" name="emailAddress" className="form-control" placeholder="Email Address" 
                      onChange={this.handleInputChange} required maxLength={100} pattern="^\w+(\.?\w+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$" />
                    <Alert show={this.state.emailAddressValidation.dirty && !this.state.emailAddressValidation.valid} variant="danger">
                      {this.state.emailAddressValidation.validationMessage}
                    </Alert>
                  </div>
                  <div className="form-group">
                    <textarea name="message" className="form-control" rows={5} placeholder="Message" 
                      onChange={this.handleInputChange} required maxLength={500} ></textarea>
                    <Alert show={this.state.messageValidation.dirty && !this.state.messageValidation.valid} variant="danger">
                      {this.state.messageValidation.validationMessage}
                    </Alert>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-learn2stock" disabled={this.sendMessageButtonDisabled()}>Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>    
        </div>
      </section>
    );
  };
}

export default ContactUs;