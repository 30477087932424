import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookF, faInstagram, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

class Footer extends React.Component {
  render() {
    return (
      <footer className="bg-green-dust-scratch mt-auto spacer-y-1">
        <div className="container">
          <ul className="list-unstyled d-md-flex mb-0">
            <li className="mr-md-2">
              <Link to="/register" className="text-dark">Register</Link>
            </li>
            <li className="mx-md-2">
              <Link to="/about-us" className="text-dark">About us</Link>
            </li>
            <li className="mx-md-2">
              <Link to="/contact-us" className="text-dark">Contact Us</Link>
            </li>
            <li className="mx-md-2">
              <Link to="/refund-policy" className="text-dark">Return, Refund, &amp; Cancellation Policy</Link>
            </li>
            <li className="mx-md-2">
              <Link to="/terms-and-conditions" className="text-dark">Terms &amp; Conditions</Link>
            </li>
          </ul>
          <div className="row justify-content-between align-items-center mt-2">
            <div className="col-lg-auto col-12 order-lg-2">
              <ul className="list-social-links d-flex mb-2 mb-lg-0">
                <li className="text-white">
                  <a href="https://www.facebook.com/groups/Learn2Stock/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="text-dark" icon={faFacebook} />
                  </a>
                </li>
                <li className="text-white">
                  <a href="https://www.facebook.com/Learn2Stock/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="text-dark" icon={faFacebookF} />
                  </a>
                </li>
                <li className="text-white">
                  <a href="https://twitter.com/learn2stock" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="text-dark" icon={faTwitter} />
                  </a>
                </li>
                <li className="text-white">
                  <a href="https://www.instagram.com/learn2stock_official/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="text-dark" icon={faInstagram} />
                  </a>
                </li>
                <li className="text-white">
                  <a href="https://www.tiktok.com/@learn2stock?lang=en" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="text-dark" icon={faTiktok} />
                  </a>
                </li>
                <li className="text-white">
                  <a href="https://www.youtube.com/channel/UCp7W8OsZG4pKyn_9w3RFWAQ" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="text-dark" icon={faYoutube} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col order-lg-1">
              <span className="text-small text-dark opacity-50">&copy; Garrett Gray LLC. All rights reserved.</span>
            </div>
          </div>
        </div>
      </footer>
    );
  };
}

export default Footer;