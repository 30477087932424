import React from 'react';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';

class ErrorBoundary extends React.Component {
    state = {
        hasError: false
    };
    modalOptions: IModalOptions = {
      title: "ERROR",
      body: "Something went wrong loading the page.",
      action: "Close",
      show: true,
      closeModal: () => { window.location.href = '/'; }
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    };

    render() {
        if (this.state.hasError) {
            return <ModalWindow modalOptions={this.modalOptions}></ModalWindow>;
        }
        return this.props.children; 
    };
}

export default ErrorBoundary;