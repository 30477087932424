import React from 'react';
import { glossaryCollection } from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import ITerm from '../interfaces/ITerm';
import ModalWindow from './ModalWindow';

class Glossary extends React.Component {
  state = {
    searchTerm: "",
    termsRetrieved: false,
    showModal: false
  };
  terms: ITerm[] = [];
  glossary: JSX.Element[] = [];

  modalOptions: IModalOptions = {
    title: "ERROR",
    body: "The glossary was not retrieved.",
    action: "Close",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };

  updateSearchTerm = (event: any) => {
    this.setState({ searchTerm: event.target.value });
  };

  updateGlossary = () => {
    var searchTerm = this.state.searchTerm.toLowerCase();
    this.glossary = this.terms.filter(t => t.term.toLowerCase().includes(searchTerm)).map((term) => {
      var bullets = term.bullets.map((bullet) => {
        var nestedBullets = bullet.nestedBullets.map((nestedBullet) => {
          return (
            <div key={nestedBullet}>&bull;<span className="ml-1">{nestedBullet}</span></div>
          );
        });
    
        return (
          <div key={bullet.text}>
            <div>&bull;<span className="ml-1">{bullet.text}</span></div>
            <div className="ml-3">{nestedBullets}</div>
          </div>
        );
      });
    
      return (
        <div key={term.term} className="py-1">
          <b>{term.term}</b><span> - {term.definition}</span>
          <div className="ml-3">{bullets}</div>
        </div>
      );
    });
  };

  componentDidMount = () => {
    glossaryCollection.get().then((querySnapshot) => {
      querySnapshot.forEach(doc => {
        let term = doc.data() as ITerm;
        this.terms.push(term);
      });
      this.terms = this.terms.sort((a, b) => {
        if(a.term < b.term) return -1;
        if(a.term > b.term) return 1;
        return 0;
      });
      this.setState({ termsRetrieved: true });
    }).catch(() => {
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };
  
  render() {
    this.updateGlossary();
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="mb-3">
            <input type="text" placeholder="Search Glossary" className="form-control" onChange={this.updateSearchTerm} />
          </div>
          {this.glossary}          
        </div>
      </section>
    );
  };
}

export default Glossary;